import React from 'react';

import { Link } from 'gatsby';

import errorStyles from './error.module.css';

export default () => (
  <div id="divContactContainer" className={errorStyles.errorContainer}>
    <div className={errorStyles.errorCrossContainer}>
      <div className={errorStyles.errorHeader}>404</div>
    </div>
    <div className={errorStyles.errorMessage}>
      <h2>Ooops! There has been a problem</h2>
      <p>The page you are looking for cannot be found</p>
      <p>
        We have recently updated the website & the page you are trying to visit may have moved.
        Please go to our
        {'  '}
        <Link className={errorStyles.errorButton} to="/">
          Homepage
        </Link>
        {'  '}
        to continue viewing the products & services Wow Interior Design offer
      </p>
      <p>
        or alternatively contact us by emailing us at
        {' '}
        <a href="mailto:info@wowinteriordesign.com" title="email" className={errorStyles.errorLink}><strong>info@wowinteriordesign.com</strong></a>
        {' '}
        or calling us on
        {' '}
        <a href="tel:01303847480" title="phone" className={errorStyles.errorLink}><strong>01303 847480</strong></a>
      </p>
    </div>
  </div>
);
