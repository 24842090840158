import React from 'react';

import Layout from '../components/layout';
import Menu from '../components/menuComponent';
import Error from '../components/errorComponent';

const NotFoundPage = () => (
  <Layout
    title="Error 404: Not Found | Wow Interior Design"
    description="Error 404 - Page cannot be found"
  >
    <Menu />
    <Error />
  </Layout>
);

export default NotFoundPage;
